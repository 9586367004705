import React from 'react';
import { Link } from 'gatsby';
import config from '@config/config';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const StayConnectedConfirmation = ({location}) => (
	<div className='page-stay-connected-confirmation'>
		<div className='hero'>
			<div className=''>
				<div className='container hero__container hero__container__overflow'>
					<div className='row'>
						<div className='col-12 col__indent--left'>
                            <h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
                                Thank you
                            </h1>

                            <div className='stay-connected-confirmation__content'>
                                <p className='text__title--light normal-text-weight'>Thank you for signing up to receive updates from the More Than Tired&nbsp;Program.</p>

                                <p className='text__title--light normal-text-weight'>Share some additional information about yourself so that we can send you relevant and helpful&nbsp;information.</p>

                                <ModalExternalLink link={`https://cloud.updates.jazzpharma.com/gettingtoknowyou/?ExternalId=${location?.state?.guid}&TriggerCode=NMCUR`}>
                                    <TrackingWrapper gtmTrackId='stay-connected-confirmation-tell-us-more'>
                                    <div
                                        target='_blank'
                                        className='btn btn--primary mt-10 btn__stay-connected-confirmation'>
                                            <span>
                                                Tell Us More About&nbsp;Yourself
                                            </span>
                                    </div>
                                    </TrackingWrapper>
                                </ModalExternalLink>

                                <div className='return-to-links'>
                                    <TrackingWrapper gtmTrackId='stay-connected-confirmation-return-home'>
                                        <Link className='back-to-home-link' to="/">Return Home</Link>
                                    </TrackingWrapper>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default StayConnectedConfirmation;
